body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* IF we want to add extra option to date range picker */
/* would remove options list */
/* .rdrStaticRanges{
  display: none !important;
}

.rdrStaticRanges{
  display: none !important;
}
.rdrInputRanges .rdrInputRange:last-child {
  display: none !important;
}
.rdrDayDisabled .rdrEndEdge{
  background-color:rgb(248, 248, 248) !important;
  color:grey;
} */

.whiteBackground > div {
  background-color: white !important;
}
.my-iframe {
  padding: 0 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* TODO: add to mercury.css */
.mercury-cancel-ca {
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  position: absolute;
  right: 5px;
  bottom: 4px;
  background: none;
  border: none !important;
  box-shadow: none;
  color: #437ef7;
}
.mercury-cancel-ca:disabled {
  display: none;
}
.mercury-cancel-ca:hover {
  background: white !important;
  border: none !important;
  color: #437ef7;
}
.mercury-cancel-ca:active {
  background: white !important;
  border: none !important;
  color: #437ef7 !important;
}
.mercury-cancel-ca:focus {
  background: white !important;
  border: none;
  box-shadow: none !important;
  color: #437ef7;
}
.mercury-cancel-ca:focus-visible {
  border: none !important;
}

/* Hides option table */
.rdrDefinedRangesWrapper {
  display: none !important;
}

:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: lightgrey !important;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  color: #f8f8f8 !important;
}

.form-control.error {
  border: 1px solid #ff0039 !important;
  color: #ff0039 !important;

  /* Adjust the value based on your icon's size and spacing */

  /* Add background image to the input */
  background-image: url('./assets/icons/alert.svg') !important;
  background-repeat: no-repeat;
  background-position: right center;
}

button[name='data[callFlutter]'] {
  display: none !important;
}

/* Temporary need to add correct ICON but for know have color match */
[data-testid='CalendarIcon'] {
  color: #9599a1 !important;
  margin-left: 6px;
}

/* Data GRID */
.no-check-box .MuiDataGrid-columnHeader:first-child,
.no-check-box .MuiDataGrid-cell:first-child {
  padding-left: 24px !important;
}

.MuiDataGrid-columnHeaders {
  min-height: 44px !important;
  max-height: 44px !important;
}

/* Text M/Medium */
.e-input input,
.e-input .MuiSelect-select,
input {
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
}
/* FOR LOGIN input */
.login-input {
  font-size: 32px !important;
}
.MuiMenu-list li {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

/* Notifications pagination */
.hide .MuiDataGrid-main {
  display: none !important;
}

.hide.MuiDataGrid-root {
  border: 1px solid #eaebf0 !important;
  border-top: 1px solid #eaebf0 !important;
  border-radius: 5px !important;
}

.hide > .MuiBox-root {
  border-top: none !important;
  border-radius: 5px !important;
}

.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinners input {
  text-align: center;
}

.no-spinners input::-webkit-inner-spin-button,
.no-spinners input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.sm-label-required::after {
  content: ' *';
  /* color: red; */
}

.edify-drawer p {
  overflow: clip;
  text-overflow: ellipsis;
}

.white-space-wrap {
  white-space: wrap !important;
}

/* .ellipsis-text {
  white-space: nowrap;  
  overflow: hidden;         
  text-overflow: ellipsis;  
} */

input:placeholder-shown {
  text-overflow: ellipsis;
}

.Mui-expanded + ul {
  border-left: 1px solid #d4d4d3;
  margin-left: 40px;
}

/* Expirament for custom file pond  */
/* .filepond--file-wrapper {
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  margin:12px;
}

.filepond--item {
  background-color: #f0f0f0;
  border-radius: 8px;
} */

.filepond--file-info {
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  /* color: #323539 */
}

/* .filepond--file-status {
  color: green;
}

.filepond--file-action-button {
  background-color: #007bff;
}
.filepond--action-retry-item-load{
  display:none
} */

.filepond--drop-label {
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  /* color: #323539 */
}
.filepond--file-wrapper > legend {
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  /* color: #323539 */
}

/* .filepond--list {
  margin-top: 24px;
} */

a > .small-pdf {
  width: 70px !important;
  margin-bottom: 18px;
  margin-bottom: 18px;
  /* height: 100px !important; */
}
.pdf-image-replace {
  margin-bottom: 18px;
  margin-top: 18px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html,
  body,
  :root {
    height: 100%;
    line-height: 25px;
  }
  
  button {
    cursor: pointer;
  }
  
  .app-container {
    display: flex;
    gap: 8px;
    background-color: #151515;
    color: #999999;
    min-height: 100vh;
    overflow-x: auto;
  }
  
  .sidebar {
    padding: 20px;
    width: 300px;
    border-right: 1px solid #353535;
  }
  
  .heading-title {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  /* Arborist component */
  
  .node-container,
  .node-content {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
  }
  
  .node-content {
    cursor: pointer;
  }
  
  .node-content span.arrow {
    width: 20px;
    font-size: 20px;
    display: flex;
  }
  
  .node-content span.file-folder-icon {
    margin-right: 7px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  
  .node-content span.node-text {
    flex: 1;
  }
  
  .node-content input[type="text"],
  .search-input {
    padding: 0px 10px;
    outline: none;
    color: black;
    border-radius: 2px;
  }
  
  .node-content input[type="text"] {
    width: 97%;
    /* border: 1px solid #878787; */
    background: transparent;
    height: 22px;
  }
  
  .search-input {
    width: 100%;
    height: 30px;
    background: #2d2c2c;
    border: none;
    margin: 1rem 0;
  }
  
  .search-input:focus {
    border: 1px solid #878787;
  }
  
  .file-actions {
    height: 100%;
    display: flex;
  }
  
  .file-actions button {
    cursor: pointer;
  }
  
  .folderFileActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
  }
  
  .folderFileActions button {
    display: flex;
    align-items: center;
    color: #999999;
    background-color: inherit;
    border: none;
    font-size: 16px;
    height: 100%;
    width: 24px;
  }
  .folderFileActions div {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100px;
  }
  
  .node-container .file-actions .folderFileActions {
    visibility: hidden;
  }
  .node-container:hover .file-actions .folderFileActions {
    visibility: visible;
  }
  
  [role="treeitem"]:hover {
    background-color: #F5F5F5;
    /* color: black; */
  }
  
  [role="treeitem"]:focus-within {
    /* background-color: red; */
    /* outline: none; */
  }
  
  /* Selected node */
  /* '#DDDFE4'
  static readonly neutral600 = '#EAEBF0' */
  .node-container.isSelected {
    background: #DDDFE4;
    /* color: black; */
    border-radius: 3px;
  }
  
  /* Right side content */
  .content {
    flex: 1;
    padding: 32px;
  }
  
  .content ul {
    list-style: none;
  }
  
  .content ul li:nth-child(2),
  .content ul li:nth-child(3) {
    margin-bottom: 1rem;
  }
  
  /* .content ul li:nth-child(3) {
    color: black;
    font-weight: bold;
  } */
  
/* FOR ALL */
.filepond--drop-label {
  background-color: white;
}
.filepond--credits {
  display: none;
}

/* just a link button */
.no-upload-ui .filepond--file-wrapper,
.no-upload-ui .filepond--root .filepond--list-scroller,
.no-upload-ui .filepond--root .filepond--data,
.no-upload-ui .filepond--root .filepond--drip,
.no-upload-ui .filepond--list,
.no-upload-ui
  .filepond--root
  .filepond--assistant
  .no-upload-ui
  .filepond--root
  .filepond--panel-top
  .no-upload-ui
  .filepond--item
  .filepond--panel-root {
  display: none !important;
  transform: none !important;
  transition: none !important;
  background-color: transparent !important;
}
.no-upload-ui .filepond--panel-top,
.no-upload-ui .filepond--panel-bottom {
  background-color: transparent !important;
}
.no-upload-ui .filepond--browser {
  height: 24px;
}

.no-upload-ui {
  transition: none;
  padding: 16px 12px !important;
}
.no-upload-ui .filepond--root .filepond--list-scroller {
  display: none;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  transition: none !important;
  transform: none !important;
}

.no-upload-ui .filepond--root {
  margin-right: 16px;

  margin-right: 0px;
  margin-bottom: 0px;
  transition: none;
}
.no-upload-ui .filepond--wrapper {
  width: auto;
  transition: none;
  /* height: 22px; */
}

.no-upload-ui .filepond--drop-label {
  width: auto;
  min-height: 0px !important;
  transition: none;
}
.no-upload-ui .filepond--drop-label {
  width: inherit;
}
.no-upload-ui .filepond--root .filepond--item,
.no-upload-ui .filepond--root .filepond--panel-center,
.no-upload-ui .filepond--root .filepond--panel-top {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: 0px !important;
  transition: none;
}

.no-upload-ui .filepond--root .filepond--drop-label,
.no-upload-ui .filepond--root .filepond--drop-label label {
  color: #437ef7;
  font-family: 'inter', sans-serif;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer !important;
  white-space: nowrap;
  text-align: right;
  /* padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px; */
  display: flex;
  width: auto;
  visibility: visible !important;
  opacity: 1 !important;
  transition: none;
  transform: none !important;
  /* SAME PADDING AS EDIFY BUTTON */
  padding: 16px 12px !important;
  background-color: transparent !important;
}

.filepond--drop-label {
  margin-top: 10px;
  /* height: 108px; This doesn't work need this for the + icon */
  background-color: white;
}
.no-upload-ui .filepond--drop-label {
  margin-top: 0px;
  /* height: 108px; This doesn't work need this for the + icon */
  background-color: white;
}
.filepond--label-action {
  /* Primary600 */
  color: #437ef7;
  text-decoration: none;

  font-family: Inter, sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
  margin-bottom: 4px;
}
.filepond--drop-file-text {
  color: #323539;
  font-family: Inter, sans-serif;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: -0.01em;
  margin-bottom: 4px;
}
.filepond--panel-top,
.filepond--panel-root {
  background-color: white;
}
/* .filepond--list{
  margin-top: 40px;
}
.filepond--wrapper {
  height:128px;
  padding-top: 30px;
} */

.filepond--root:hover,
.filepond--root label:hover,
filepond--drop-label:hover {
  cursor: pointer;
}

label:hover {
  /* grey50 */
  color: #5a6472;
}
.no-upload-ui label {
  /* primary600 */
  color: #0a77ff !important;
}
.no-upload-ui:hover label:hover {
  /* primary500 */
  color: #2c8aff  !important;;
}
